import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import MainContainer from '../containers/MainContainer';
import PrivacyScreen from '../screens/PrivacyScreen';
import TosScreen from '../screens/TosScreen';
import DownloadRedirect from '../components/downloadRedirect';

class App extends React.Component {
  render() {
    return (
      <div className='App'>
        <Router>
          <Switch>
            <Route path='/download'>
              <DownloadRedirect />
            </Route>
            <Route path='/privacy'>
              <PrivacyScreen />
            </Route>
            <Route path='/tos'>
              <TosScreen />
            </Route>
            <Route path='/'>
              <MainContainer />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
