import { Route } from 'react-router-dom';
import React from 'react';

interface Props {
  exact?: boolean;
  link: string;
  path?: string;
  sensitive?: boolean;
  strict?: boolean;
}

const ExternalRedirect: React.FC<Props> = (props: Props) => {
  const { link, ...routeProps } = props;

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...routeProps}
      render={() => {
        window.location.replace(link);
        return null;
      }}
    />
  );
};

export default ExternalRedirect;
