/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import ExternalRedirect from './externalRedirect';

class DownloadRedirect extends React.Component {
  render() {
    return (
      // eslint-disable-next-line
      <ExternalRedirect link={'https://link.stapps.nl/download'} />
    );
  }
}

export default DownloadRedirect;
