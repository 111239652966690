import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import { CircularProgress, CardContent, Paper } from '@material-ui/core';
import source from './tos.md';

type State = {
  content: string;
};

export default class PrivacyScreen extends Component<{}, State> {
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = { content: '' };
    this._getContent();
  }

  async _getContent(): Promise<void> {
    const content = await fetch(source).then(res => res.text());
    this.setState({
      content,
    });
  }

  render() {
    return (
      <div style={{ width: 'auto', height: 'auto', padding: '2%' }}>
        <Paper elevation={8.0}>
          <CardContent>
            {this.state.content ? (
              <ReactMarkdown source={this.state.content} />
            ) : (
              <CircularProgress />
            )}
          </CardContent>
        </Paper>
      </div>
    );
  }
}
