import React from 'react';
import logo from './logo.svg';

export default class MainContainer extends React.Component {
  render() {
    return (
      <div>
        <img src={logo} className='App-logo' alt='logo' />
        Er wordt aan deze site gewerkt...
      </div>
    );
  }
}
